
.callscreen {
  text-align:center;
}

.callscreen .options {
  position:absolute;
  z-index:2;
  bottom:0;
  padding-bottom:1vh;
  margin:auto;
  left: 50%;
  transition:opacity 0.5s ease;
  transform:translateX(-50%);
  width:100%;
  box-shadow:0 -12vh 20vh -12vh rgba(0, 0, 0, 0.9) inset;
}

.callscreen .roundIcon {
  background-color:white;
  margin:0.75em;
}

.callscreen .roundIcon svg {
  fill:black;
  transition:all 0.2s ease;
}

.callscreen .roundIcon.active svg {
  fill:#63a6e4;
}

.callscreen .roundIcon.pressed,
.callscreen .roundIcon:active {
  filter: brightness(0.8);
}

.roundIcon.endCall {
  background-color:#d80000;
}

.roundIcon.endCall svg {
  fill:white;
  transform: rotateZ(135deg);
}

.callscreen .roundIcon.switchCam {
  background-color:transparent;
}

.callscreen .roundIcon.switchCam svg {
  fill:white;
}

.callscreen .streams {
  position:absolute;
  display: flex;
  left:0;
  bottom:0;
  top:0;
  right:0;
  z-index:1;
  overflow:hidden;
  background-color:black;
  align-items:start;
  justify-content: flex-start;
}

.streams .contactname {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  display: flex;
  align-items:center;
  justify-content: center;
  padding:0.5em;
  color:white;
  opacity:0;
  background-color:rgba(0, 0, 0, 0.75);
  transition:opacity 0.3s ease;
  z-index:2;
}

.streams .smallStream .contactname:hover {
  opacity:1;
}

.callscreen .options:hover + .camstreams .bigStream .contactname {
  opacity:1;
}

.callscreen .localStreamContainer,
.callscreen .remoteStreamContainer {
  transition:transform 0.3s ease;
  overflow:hidden;
  position:relative;
}

.callscreen .streams > div {
  display: inline-block;
  bottom:auto;
  left:auto;
  width:auto;
  height:auto;
  border-radius:1em;
  box-shadow:0 0 0.5em 0 rgba(0, 0, 0, 0.3);
}

.callscreen .streams > .bigStream {
  position:absolute;
  border-radius:1px;
  box-shadow:none;
  top:0;
  left:0;
  bottom:0;
  right:0;
  z-index:4;
}

.callscreen .streams > .smallStream {
  cursor:pointer;
  z-index:5;
  margin:1em;
  transition:all 0.5s ease 0s;
  transform-origin:left top;
}

.callscreen .streams > .smallStream video {
  min-width:0;
  min-height:0;
  max-height:25vh;
  max-width:25vw;
  position:static;
  transform:none;
  display: block;
}

.callscreen video {
  min-width:100%;
  min-height:100%;
  position:absolute;
  top:50%;
  left:50%;
  transform:translateX(-50%) translateY(-50%);
}

.callscreen .streams > .smallStream video.localStream {
  transform:scale(-1, 1);
}
.callscreen video.localStream {
  transform:translateX(-50%) translateY(-50%) scale(-1, 1);
}

.callscreen .bigStream video[data-orientation="landscape"] {
  width: 100%;
  height:auto;
  min-width:none;
  min-height:none;
  max-height:100%;
  max-width:100%;
}

.callscreen .bigStream video[data-orientation="portrait"] {
  height: 100%;
  width:auto;
  max-height:100%;
  max-width:100%;
  min-height:none;
  min-width:none;
  max-height:100%;
  max-width:100%;
}

.callscreen .zoomed .bigStream video {
  min-height:100%;
  min-width:100%;
  max-height:none;
  max-width:none;
  width:auto;
  height:auto;
}