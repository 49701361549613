body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -webkit-app-region:drag;
}

.metadata-inquiry-header {
  background-color:#487ba9;
  color:white;
  font-size:120%;
  user-select:none;
  min-width:50%;
}

.metadata-inquiry-header svg {
  color:white!important;
}

.languageswitcher {
  margin:auto;
  z-index:3;
}

.languageswitcher ul {
  text-indent:0;
  padding:0;
}

.languageswitcher li {
  color:white;
  text-indent:1000px;
  user-select: none;
  overflow:hidden;
  white-space:nowrap;
  display:inline-block;
  position:relative;
  width:2em;
  height:2em;
  font-size:1.5em;
  border-radius:2em;
  cursor:pointer;
  margin:0.5em;
  background-color:#c4c4c4;
}

.languageswitcher li.active {
  cursor:normal;
  background-color:#009b3d;
}

.languageswitcher li:before {
  text-indent:0;
  content:attr(data-lang);
  text-transform:uppercase;
  color:white;
  position:absolute;
  margin-top:-0.05em;
  font-size:80%;
  top:50%;
  left:50%;
  display:block;
  transform:translateX(-50%) translateY(-50%);
}


.outerShell {
  background-color:#009a3c;
  transition:all 0.3s ease;
}

.outerShell::before {
  content:' ';
  position:absolute;
  transition:all 0.3s ease;
  top:0;
  left:0;
  right:0;
  height:100vh;
  width:100vw;
  background: linear-gradient(152deg, rgba(255, 255, 255, 0.42) 0%, rgba(0,0,0,0.42) 100%);
  z-index:3;
}

.outerShell::after {
  content:' ';
  transition:all 0.3s ease;
  box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.32) inset;
  position:absolute;
  top:0;
  left:0;
  height:100vh;
  width:100vw;
  right:0;
  z-index:2;
}

.flat.outerShell {
  background-color:white;
  color:#444751;
}

.flat.outerShell::before,
.flat.outerShell::after {
  display: none;
}

.flat.outerShell::before {
  height:5vh;
  background:#009a3c;
  display: block;
}

.flat .appbar {
  background-color:white!important;
  color:#444751!important;
}
.flat .appbar button {
  color:#444751!important;
}

.login {
  z-index:5;
  max-width:30em;
  position:fixed;
  top:50%;
  left:50%;
  transform:translateX(-50%) translateY(-50%);
  width: 60%;
}

@media (max-width: 640px) {
  .login {
    width:90%;
    position:absolute;
    top:10%;
    left:50%;
    transform:translateX(-50%);
  }

  .appbar {
    display:none!important;
  }
}

.outerShell.loggedin {
  background-color:white;
}

.outerShell.loggedin::before {
  opacity:0;
}

.outerShell.loggedin::after {
  opacity:0;
}

.app {
  display:flex;
  flex-direction:row;
  overflow:hidden;
}

.callStatus .actions span {
  margin-top:1em;
}

.contentArea {
  flex:4;
  margin:15px;
}

.mainContent * {
  user-select:text;
}

.mainContent h2 {
  border-bottom: 1px solid #999;
  padding-bottom:0.3em;
  margin-bottom:0.35em;
}

.logo {
  max-width:340px;
  margin:auto;
  margin-left:16px;
  border:0;
  height:75%;
}

.app .contactList {
  transition: all 0.5s ease;
  flex:1;
  overflow:hidden;
}

.app.calling .contactList {
  flex-grow: 0.000001;
  white-space:nowrap;
}
.app .contactList .state {
  transition: all 0.3s ease;
}
.app.calling .contactList .state {
  opacity:0;
}

.contactListItem:hover {
  background-Color:rgba(0, 0, 0, 0.05);
}

.callscreencontainer, .infoContainer {
  transition: all 0.5s ease;
  flex-grow: 0.000001;
}

.infoContainer {
  flex:1;
  flex-grow: 0.00001;
  overflow-y:auto;
  overflow-x:hidden;
  position:relative;
  border-left:0px solid rgb(204, 204, 204);
}

.infoshown .infoContainer {
  flex-grow:2;
  border-left-width:1px;
}

.app.calling .callscreencontainer {
  flex-grow:3;
}

.infoButton svg {
  width:40px;
  height:40px;
}

.infoButton svg:hover {
  opacity:1;
}

.infocontact {
  white-space:nowrap;
}

.contactheader {
  padding:16px;
  box-sizing:border-box;
  vertical-align:top;
}

.contactheader .name > div {
  font-size:90%;
  vertical-align:middle;
}

.callscreen .options {
  opacity:0;
  min-width:60vw;
}

.app.calling .callscreen .options {
  opacity:1;
}

.chatcontainer {
  transition: all 0.5s ease;
  flex: 2;
}

.app.calling .chatcontainer {
  flex-grow:0.0000001;
}

.appbar {
  transition:margin 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.5s!important;
}

.calling .appbar {
  margin-top:-64px;
}

.helpscreen a {
  text-decoration:none;
  color:inherit;
}

.chat {
  font-size:1.1em;
}

.chat .message .content {
  padding:0.25em 0.6em!important;
  font-weight:normal!important;
}

.contactWindow .streams {
  display: flex;
  width:100%;
  overflow:hidden;
}

.incomingCalls {
  position:fixed;
  top:0px;
  left:50%;
  transform:translateX(-50%);
}

.infocontact {
  display:flex;
  flex-direction:column;
}

.info {
  display: flex;
}

.info > div {
  flex:1;
}
