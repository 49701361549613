
.roundIcon {
  display:inline-block;
  width:9.4vh;
  height:9.4vh;
  min-width: 50px;
  min-height: 50px;
  max-height: 80px;
  max-width: 80px;
  background-color:white;
  position:relative;
  border-radius:100%;
  margin:0 3vh;
  cursor:pointer;
}

.roundIcon span {
  display: none;
}

.roundIcon svg {
  fill: #6a6a6a;
  height: 60%;
  width: 60%;
  position:absolute;
  top:20%;
  left:20%;
}

.roundIcon::after {
  position:absolute;
  content: " ";
  top:-0.2em;
  right:-0.2em;
  display: block;
  background-color:orange;
  width: 1.5em;
  height:1.5em;
  border-radius:1em;
  transform:scale(0);
  transition:all 0.3s ease;
  transition-timing-function: cubic-bezier(0.54, 0.12, 0, 1.59)
}

.roundIcon.iconIndicator::after {
  transform:scale(1);
}