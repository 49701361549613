

.hasOverlay > div {
  filter:blur(30px);
}

.hasOverlay .options {
  opacity:0!important;
}

.frosted {
  filter:none!important;
  display:flex;
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  overflow:hidden;
  right:0;
  margin:auto;
  text-align:center;
  text-transform:uppercase;
  color:white;
  z-index:1000;
  flex-direction:column;
}

.frosted > div {
  flex:1;
  justify-content: center;
  align-items: center;
  display:flex;
}

.frosted .actions {
  position:relative;
  display:flex;
  align-items: center;
  justify-content: center;
  left:50%;
  transform:translateX(-50%);
  max-width:550px;
}

.frosted .actions > div {
  flex:1;
}

.frosted .actions > div {
  display:inline-block;
}

.frosted .actions span {
  display: block;
}

.frosted::before {
  content: ' ';
  overflow:hidden;
  width:100%;
  height:100%;
  display:block;
  position:absolute;
  background-color:rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 50vh 0px rgba(0, 0, 0, 1) inset;
  z-index:-1;
}

.frosted .actions > div:active {
  filter:brightness(0.75);
  cursor:pointer;
}
