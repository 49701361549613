
.chat .messages {
  flex:1;
  overflow-y:auto;
  padding-bottom:0.5em;
}

.chat .compose {
  background-color:#fafafa;
  border-top:1px solid #c1bebb;
}

.chat .compose input {
  font-size:16px!important;
}

.inquiry {
  max-width: 523px;
  min-width: 60%;
}

.chat .message {
  display:block;
  margin:1em 0 0 0;
  padding:0 1em;
}

.chat .message {
  position:relative;
}

.chat .message a {
  color:inherit;
}

.chat .message.event {
  opacity:0.75;
}

.chat .message.event.local .content,
.chat .message.event.remote .content {
  background-color:transparent;
  color:#666;
  font-weight:600;
  padding:0.2em 0.6rem!important;
  font-size:90%;
}
.chat .message.event .bubble {
  display:none;
}

.chat .message .content {
  border-radius:0.5em;
  padding:0.2em 0.75em;
  display:inline-block;
  max-width:80vw;
  word-break:break-word;
  hyphens:auto;
}

.chat .message.local {
  text-align:right;
}

.chat .message.remote {
  text-align:left;
}

.chat .message .content {
  font-weight:600;
}

.chat .message.local .content { 
  background-color:#63a6e4;
  color:white;
}

.chat .message .author {
  color:#7a7a7a;
  font-weight:600;
  font-size:0.8em;
  padding:0 0.75em;
}

.message.local + .message.local {
  margin-top:0.15em;
}

.message .author {
  display:none;
}

.message.showAuthor .author {
  display: block;
}
.message.remote + .message.remote {
  margin-top:0.15em;
}

.message.remote + .message.remote.showAuthor {
  margin-top:0.75em;
}


.chat .message.remote .content { 
  background-color:#e4e4e4;
}

.chat .message .bubble {
  position:absolute;
  bottom:0.3em;
  width:1em;
  height:auto;
}

.chat .message.local .bubble {
  fill: #63a6e4;
  right:0.1em;
}

.chat .message.remote .bubble {
  fill: #e4e4e4;
  left:0.1em;
  transform:rotateY(-180deg);
}

.chat .compose form {
  display:flex;
  align-items: center;
  justify-content: center;
}

.chat .compose form input[type=text] {
  flex:1;
  width:100%;
  display:inline-block;
  margin:0.5em;
  margin-right:0.5em;
  padding:0.5em 1em;
  height:100%;
  border-radius:1em;
  outline:none;
  border:1px solid #e1e1e1;
  background-color:white;
  font-size:inherit;
  font-weight:inherit;
}

.chat .compose form button {
  min-width:40px;
  width:1em;
  height:100%;
  background-color:transparent;
  border:0;
  position:relative;
  outline:none;
}

.chat .compose form button span {
  display: block;
  overflow:hidden;
  width:3em;
  white-space:nowrap;
  text-indent:9999px;
}

.chat .compose form button > div {
  height:0;
  padding-bottom:100%;
  position:absolute;
  top:50%;
  left:50%;
  transform:translateX(-50%) translateY(-50%);
  display:inline-block;
  overflow:hidden;
}

.chat .compose form button svg {
  position:absolute;
  top:10%;
  left:10%;
  width:80%;
  height:80%;
  fill:#007cfd;
}